import React from 'react'
import { Link, useStaticQuery } from 'gatsby'
import Layout from '../../components/Layout'



class PoliticaPrivacidad extends React.Component {

    render() {

        return (
            <Layout title={"Política de privacidad | Modelizame"} description={"Política de privacidad"}>
                
                <section className="section margin-top-navbar" style={{minHeight: '80vh'}}>
                    <div className="container">
                        <h4 className="title is-4">
                        Política de privacidad
                        </h4>
                        <p className='mb-40'>
                            Modelizame, S.L., (en adelante el responsable), es el responsable del tratamiento de los datos personales del usuario y le informa que estos datos serán tratados de conformidad con lo dispuesto en el Reglamento (UE) 2016/679 de 27 de abril (GDPR) y la Ley Orgánica 3/2018 de 5 de diciembre (LOPDGDD), por lo que se le facilita la siguiente información del tratamiento.
                        </p>
                        <h4 className="title is-4">
                        Fin del tratamiento
                        </h4>
                        <p>
                            Mantener una relación comercial con el usuario. Las operaciones previstas para realizar el tratamiento son:
                        </p>
                        <ul className='mb-40' style={{listStyleType:'disc', paddingLeft:'40px'}}>
                            <li>
                                Remisión de comunicaciones comerciales publicitarias, comunicaciones en referencia a los servicios, cuestiones técnicas o cambios en los mismos, por correo electrónico, SMS, WhatsApp, comunidades sociales o cualquier otro medio electrónico o físico, presente o futuro, que posibilite realizar comunicaciones. Estas comunicaciones serán realizadas por el responsable y relacionadas con sus servicios, o de sus colaboradores o proveedores con los que éste haya alcanzado algún acuerdo de promoción. En este caso, los terceros nunca tendrán acceso a los datos personales.
                            </li>
                            <li>
                                Los datos recibidos por cualquiera de los medios y/o canales de contacto indicados en el sitio Web, se utilizan con la finalidad de gestionar las solicitudes o incidencias comunicadas por los interesados.
                            </li>
                            <li>
                                Realizar estudios estadísticos.
                            </li>
                            <li>
                                Tramitar cualquier tipo de petición que sea realizada por el usuario a través de cualquiera de las formas de contacto que se ponen a su disposición.
                            </li>
                            <li>
                                Remitir el boletín de noticias del sitio Web.
                            </li>
                            <li>
                                Gestionar el acceso y la utilización de los servicios por parte de los usuarios.
                            </li>
                        </ul>
                            
                        <h4 className="title is-4">
                            Base jurídica del tratamiento
                        </h4>
                        <p className='mb-40'>
                            Consentimiento del interesado.
                        </p>

                        <h4 className="title is-4">
                            Criterios de conservación de los datos
                        </h4>

                        <p className='mb-40'>
                            Se conservarán durante no más tiempo del necesario para mantener el fin del tratamiento y cuando ya no sea necesario para tal fin, se suprimirán con medidas de seguridad adecuadas para garantizar la pseudonimización de los datos o la destrucción total de los mismos.
                            En cumplimiento de las disposiciones de la Ley 25/2007, de 18 de octubre, de conservación de datos relativos a las comunicaciones electrónicas y a las redes públicas de comunicaciones, Modelizame deberá de retener determinados datos generados o tratados por los mismos, con el fin de posibilitar que dispongan de ellos los agentes facultados.
                        </p>
                        <h4 className="title is-4">
                            Comunicación de los datos
                        </h4>
                        <p>
                            El responsable informa y garantiza expresamente a los usuarios que sus datos personales no serán cedidos en ningún caso a terceros, a excepción de:
                        </p>
                        <ul className='mb-40' style={{listStyleType:'disc', paddingLeft:'40px'}}>
                            <li>
                                En aquellos casos en los que sea necesario para la prestación de determinados servicios.
                            </li>
                            <li>
                                Proveedores que precisen acceder a sus datos para la prestación de servicios que Modelizame haya contratado a dichos proveedores, y con los cuales tiene suscritos los contratos de tratamiento de datos personales necesarios y exigidos por la normativa para proteger su privacidad.
                            </li>
                            <li>
                                Administraciones públicas y bancos para el cumplimiento de las obligaciones legales a las que Modelizame está sujeto por su actividad.
                            </li>
                        </ul>

                        <h4 className="title is-4">
                            Derechos que asisten al Usuario
                        </h4>
                        <ul className='mb-40' style={{listStyleType:'disc', paddingLeft:'40px'}}>
                            <li>
                                Derecho a retirar el consentimiento en cualquier momento.
                            </li>
                            <li>
                                Derecho de acceso, rectificación, portabilidad y supresión de sus datos y a la limitación u oposición al su tratamiento.
                            </li>
                            <li>
                                Derecho a presentar una reclamación ante la autoridad de control (www.aepd.es) si considera que el tratamiento no se ajusta a la normativa vigente.
                            </li>
                        </ul>


                        <h4 className="title is-4">
                            Datos de contacto para ejercer sus derechos
                        </h4>

                        <p className='mb-40'>
                            Si desea ejercer alguno de los derechos expuestos, rogamos que nos envíe un correo electrónico a hola@modeliza.me con todos sus datos incluyendo una copia del DNI.
                        </p>

                        <h4 className="title is-4">
                        Carácter obligatorio de los datos proporcionados
                        </h4>
                        <p className='mb-40'>
                            Los usuarios, mediante la entrada de datos en los campos, aceptan expresamente y de forma libre e inequívoca, que sus datos son necesarios para atender su petición, por parte del prestador. El usuario garantiza que los datos personales facilitados al responsable son veraces y se hace responsable de comunicar cualquier modificación de los mismos.
                            Todos los datos solicitados a través del sitio Web son obligatorios, ya que son necesarios para la prestación de un servicio óptimo al usuario. En caso de que no sean facilitados todos los datos, no se garantiza que la información y servicios facilitados sean completamente ajustados a sus necesidades.
                        </p>
                        <h4 className="title is-4">
                            Medidas de Seguridad
                        </h4>
                        <p className='mb-40'>
                            Que de conformidad con lo dispuesto en las normativas vigentes en protección de datos personales, el responsable está cumpliendo con todas las disposiciones de las normativas GDPR para el tratamiento de los datos personales de su responsabilidad, y manifiestamente con los principios descritos en el artículo 5 del GDPR, por los cuales son tratados de manera lícita, leal y transparente en relación con el interesado y adecuados, pertinentes y limitados a lo necesario en relación con los fines para los que son tratados.
                            El responsable garantiza que ha implementado políticas técnicas y organizativas apropiadas para aplicar las medidas de seguridad que establecen el GDPR con el fin de proteger los derechos y libertades de los usuarios y les ha comunicado la información adecuada para que puedan ejercerlos.
                        </p>
                        <h4 className="title is-4">
                            Identidad del responsable
                        </h4>
                        <p className='mb-40'>
                            Modelizame S.L. (en constitución)
                        </p>


                    </div>
                        
                </section>

            </Layout>
        )
    }
}

export default PoliticaPrivacidad